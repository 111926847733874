import Controller from '@ember/controller';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import { tracked } from '@glimmer/tracking';
import { task, timeout, all } from 'ember-concurrency';
import { bind } from '@ember/runloop';
import isBefore from 'date-fns/isBefore';
import startOfDay from 'date-fns/startOfDay';

export default class InstanceVouchersIndexVoucherEditController extends Controller {
  @service authenticatedFetch;
  @service currentUser;
  @service intl;
  @service multimedia;
  @service router;
  @service store;
  @service uiAppSettings;
  @service uiDialog;

  @tracked
  isMobile = false;

  @tracked
  isDiscardChangesDialog = false;

  @tracked
  isExitingRoute = false;

  @tracked
  isFormSubmitted = false;

  /* changesets */
  /**
   * Changeset which handles validations for all properties,
   * that are shared between the different voucher-types.
   *
   * @type {Object} changeset
   * @default null
   */
  @tracked
  changeset = null;

  get isReadOnly() {
    return this.currentUser?.isViewer;
  }

  /**
   * true if the vouchers validityPeriod is set to custom
   *
   * @computed hasCustomExpiration
   * @return {Boolean} true if custom
   */
  get hasCustomExpiration() {
    return this.changeset.get('validityPeriod') === 'custom';
  }

  constructor() {
    super(...arguments);

    this.resizeListener = bind(this, this._checkWindow);
    // TODO: Listener cleanup when implementing mobile view
    window.addEventListener('resize', this.resizeListener, false);
  }

  /**
   * Executes the differnet validations on the changesets, if the validations pass
   * the changesets get merged and a request to persitst the voucher gets sent to the api.
   *
   * @type {Task}
   * @function saveVoucher
   */
  @task(function* () {
    this.isFormSubmitted = true;

    const changeset = this.changeset;

    yield changeset.validate();

    const presenceMessage = this.intl.t('errors.required');

    // Reset validityPeriodCustomDate, if the validity period is not set to "custom".
    if (changeset.get('validityPeriod') !== 'custom') {
      changeset.set('validityPeriodCustomDate', null);
    }

    // needs to be added after base validation or the validate functions clearsor the validate functions clears the error-stack
    if (this.hasCustomExpiration) {
      if (!changeset.get('validityPeriodCustomDate')) {
        changeset.addError('validityPeriodCustomDate', presenceMessage);
      } else if (
        isBefore(new Date(changeset.get('validityPeriodCustomDate')), startOfDay(new Date()))
      ) {
        // if old validityPeriodCustomDate is passed
        const beforeMessage = this.intl.t('errors.beforeDate');
        changeset.addError('validityPeriodCustomDate', beforeMessage);
      }
    }

    if (changeset.get('isValid')) {
      try {
        let tasks = [];
        // save voucher and keep the task running for at least 250ms
        tasks.push(changeset.save());
        tasks.push(timeout(250));

        yield all(tasks);
        this.isExitingRoute = true;

        this.discardChanges();
      } catch (error) {
        throw new Error('[VOUCHER]: onSave error', error);
      }
    } else {
      yield timeout(250);
    }
  })
  saveVoucher;

  /**
   * @tracked
   * Checks the current windowsize programmatically and sets the `isMobile`-prop
   * to true if the current window size is lower than `600px`.
   *
   * @function _checkWindow
   * @private
   * @return {void}
   */
  _checkWindow() {
    this.isMobile = !window.matchMedia('(min-width: 600px)').matches;
  }

  @action
  backAction() {
    if (!this.changeset.get('isPristine')) {
      this.isDiscardChangesDialog = true;
      return;
    }

    this.router.transitionTo('instance.incentive-vouchers.index.voucher', this.changeset.get('id'));
  }

  @action
  discardChanges() {
    this.changeset?.rollback();

    // get the underlying model
    const voucherModel = this.changeset.get('data');

    // remove empty model from store
    voucherModel.rollbackAttributes();
    this.isDiscardChangesDialog = false;

    this.router.transitionTo('instance.incentive-vouchers.index.voucher', this.changeset.get('id'));
  }

  @action
  closeMultimedia() {
    this.router.transitionTo(
      'instance.incentive-vouchers.index.voucher.edit',
      this.changeset.get('id')
    );
  }

  @action
  addMedium(_, [image]) {
    this.changeset.set('image', image);
  }
}
