import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/string';
import { tracked } from '@glimmer/tracking';
import { task, timeout, all } from 'ember-concurrency';

import { LAYOUT_LANDSCAPE, LAYOUT_PORTRAIT } from 'additive-voucher/utils/constants';
import { GRID_DEFAULT_SIZE } from '@additive-apps/media/constants';

export default class InstanceIncentiveVouchersIndexVoucherController extends Controller {
  @service authenticatedFetch;
  @service currentUser;
  @service intl;
  @service router;
  @service uiAppSettings;
  @service uiDialog;
  @service uiPaths;
  @service uiToast;
  @service uiFilter;

  /**
   * Indicates if template preview slider is visible or not
   *
   * @property showTemplatePreview
   * @type {Boolean}
   * @default false
   * @private
   */
  @tracked
  showTemplatePreview = false;

  /**
   * array of preview objects for the preview dialog
   *
   * @property previews
   * @type {Array}
   * @default null
   * @private
   */
  @tracked
  previews = null;

  /**
   * default size for images
   *
   * @property defaultSize
   * @type {String}
   * @default 334x240
   */
  defaultSize = GRID_DEFAULT_SIZE;

  get availableLanguages() {
    return this.uiAppSettings?.languages?.contentLanguages;
  }

  get defaultLanguage() {
    return this.uiAppSettings?.languages?.defaultLanguage;
  }

  get currentLocale() {
    return this.uiAppSettings?.currentLocale;
  }

  get isViewer() {
    return this.currentUser?.isViewer;
  }

  get greeting() {
    return htmlSafe(this.model.greeting);
  }

  get termsOfUse() {
    return htmlSafe(this.model.termsOfUse);
  }

  @task(function* () {
    yield timeout(2000);
  })
  publishAction;

  /**
   * changes the locale the model to a given locale
   *
   * @type {Task}
   * @function changeLocale
   * @param {String} locale
   */
  @task(function* (locale) {
    if (locale !== this.currentLocale) {
      this.uiAppSettings.setLocale(locale);

      let tasks = [];
      tasks.push(this.model.reload());
      tasks.push(timeout(400));

      yield all(tasks);
    }
  })
  changeLocale;

  /**
   * deletes a given locale of the voucher model
   *
   * @type {Task}
   * @function removeLocale
   * @param {String} locale
   */
  @task(function* (locale) {
    try {
      const baseUrl = this.uiPaths.pathsByRouteName('instance.incentive-vouchers').api().url;
      const url = `${baseUrl}/${this.model.id}/${locale}`;

      const response = yield this.authenticatedFetch.fetch(url, { method: 'DELETE' });

      if (!response || !response.ok) {
        throw new Error();
      }

      const json = yield response.json();
      if (!json?.voucher) {
        throw new Error();
      }

      this.store.pushPayload({ incentiveVouchers: json.voucher });

      this.uiToast.showToast({
        title: this.intl.t('toast.success'),
        type: 'success'
      });
    } catch (e) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  })
  removeLocale;

  /**
   * deletes the voucher model
   *
   * @param {Object} voucher
   * @type {Task}
   * @function deleteVoucherTask
   * @private
   */
  @(task(function* () {
    try {
      const voucher = this.model;

      let tasks = [];
      tasks.push(voucher.destroyRecord());
      tasks.push(timeout(250));

      yield all(tasks);
      yield this.uiFilter.reset('incentive-vouchers');

      this.uiToast.showToast({
        type: 'success',
        title: this.intl.t('toast.success'),
        duration: 2000
      });

      this.router.transitionTo('instance.incentive-vouchers');
    } catch (error) {
      if (error?.errors?.length > 0) {
        const errorData = error.errors[0];

        // handle conflict error
        if (errorData.status === 409) {
          const { intl } = this;
          this.uiDialog.showConflictError(
            intl.t('global.deleteWidget.conflictError.title'),
            intl.t('global.deleteWidget.conflictError.description'),
            errorData.dependencies
          );

          return;
        }
      }

      this.uiToast.showToast({
        type: 'error',
        title: this.intl.t('toast.unexpectedError'),
        duration: 2000
      });
    }
  }).drop())
  deleteVoucherTask;

  @action
  edit() {
    this.uiAppSettings.setLocale(this.model.get('language'));
    this.router.transitionTo('instance.incentive-vouchers.index.voucher.edit', this.model);
  }

  @action
  onClose() {
    this.router.transitionTo('instance.incentive-vouchers');
  }

  @action
  addLocale(lang) {
    this.changeLocale.perform(lang);
    this.router.transitionTo('instance.incentive-vouchers.index.voucher.edit', this.model);
  }

  @action
  deleteVoucher() {
    const { intl } = this;
    this.uiDialog.showConfirm(
      intl.t('vouchers.voucher.deleteVoucher.title'),
      htmlSafe(
        intl.t('vouchers.voucher.deleteVoucher.description', {
          name: this.model?.name
        })
      ),
      () => {
        this.deleteVoucherTask.perform();
      },
      intl.t('global.actions.delete'),
      true,
      true
    );
  }

  @action
  openPreviewCarousel(medium) {
    const { model } = this;
    const previews = [];

    if (model?.previewUrl && medium) {
      previews.push({
        url: `${model.previewUrl}&image=${medium.id}&amount_without_discount=${model.amount}`,
        layoutType: LAYOUT_PORTRAIT,
        maxWidth: 439
      });
      previews.push({
        url: `${model.previewUrl}&image=${medium.id}&amount_without_discount=${model.amount}&layout=landscape`,
        layoutType: LAYOUT_LANDSCAPE,
        maxWidth: 774
      });
    }

    this.previews = previews;
    this.showTemplatePreview = previews.length > 0;
  }

  @action
  onInlineNotificationClick(url) {
    window.open(url, '_blank');
  }
}
