const STATE_MAP = {
  canceled: 'error',
  expired: 'error',
  issued: 'warning',
  ordered: '',
  paid: 'success',
  partial_redeemed: 'success',
  redeemed: 'success'
};

/**
 * Returns a state mapping
 *
 * Each possible order state gets associated to a global state color
 * @param {String} state
 * @return {String} the global state representation, one of `success|error|warning|info`
 */
export const orderToState = (state) => {
  return STATE_MAP[state] || '';
};

// TODO: Try to split constants up
const PAYED_ACTION = {
    key: 'paid',
    action: 'updateState'
  },
  ISSUED_ACTION = {
    key: 'issued',
    action: 'updateState'
  },
  REDEEM_ACTION = {
    key: 'redeemVoucher',
    action: 'updateState'
  },
  EXPIRATION_ACTION = {
    key: 'extendExpiration',
    action: 'updateState'
  },
  CANCEL_ACTION = {
    key: 'canceled',
    action: 'updateState'
  };

const CONFIRM_ORDER = {
    key: 'confirm_order',
    action: 'notifyCustomer'
  },
  RESEND_VOUCHER = {
    key: 'confirm_payment',
    action: 'notifyCustomer'
  },
  REMIND_PAYMEND = {
    key: 'remind_payment',
    action: 'notifyCustomer'
  };

/* ordered, issued, paid, redeemed, canceled, expired, gift */
export const ORDER_ACTIONS_MAP = {
  ordered: {
    actions: [CONFIRM_ORDER, REMIND_PAYMEND, PAYED_ACTION, ISSUED_ACTION, CANCEL_ACTION]
  },
  paid: {
    actions: [REDEEM_ACTION, RESEND_VOUCHER, EXPIRATION_ACTION, CANCEL_ACTION]
  },
  partial_redeemed: {
    actions: [REDEEM_ACTION, RESEND_VOUCHER, EXPIRATION_ACTION, CANCEL_ACTION]
  },
  issued: {
    actions: [PAYED_ACTION, CONFIRM_ORDER, REMIND_PAYMEND, CANCEL_ACTION]
  },
  gift: {
    actions: [REDEEM_ACTION, EXPIRATION_ACTION, CANCEL_ACTION]
  },
  redeemed: {
    actions: []
  },
  canceled: {
    actions: []
  },
  expired: {
    actions: [REDEEM_ACTION, EXPIRATION_ACTION]
  }
};
