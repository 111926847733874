import {
  validateInclusion,
  validateLength,
  validatePresence
} from 'ember-changeset-validations/validators';

import { validateDate } from '@additive-apps/utils/validators/date';
import { validateNumeral } from '@additive-apps/utils/validators/numeral';

import { VOUCHER_VALIDITY_PERIODS } from 'additive-voucher/utils/constants';

/**
 * Shared validation, for all available voucher types.
 *
 * @param {Service} intl reference to the intl service
 */
export const validation = (intl) => {
  const presenceMessage = intl.t('errors.required');
  const maxLengthNameMessage = intl.t('errors.maxLength', { maxLength: 50 });
  const greaterEqualZeroMessage = intl.t('errors.greaterEqualZero');

  /* General validation for all voucher types */
  return {
    name: [
      validateLength({ max: 50, message: maxLengthNameMessage }),
      validatePresence({ presence: true, message: presenceMessage })
    ],
    greeting: validatePresence({ presence: true, message: presenceMessage }),
    category: validatePresence({ presence: true, message: presenceMessage }),
    validityPeriod: validateInclusion({ list: VOUCHER_VALIDITY_PERIODS, message: presenceMessage }),
    validityPeriodCustomDate: validateDate({
      allowBlank: true,
      format: 'yyyy-MM-dd'
    }),
    amount: [
      validatePresence({ presence: true, message: presenceMessage }),
      validateNumeral({
        gte: 0,
        allowBlank: true,
        message: greaterEqualZeroMessage
      })
    ]
  };
};
